.star-rating {
    display: flex;        /* Use flex layout */
    justify-content: center; /* Center children horizontally */
    font-size: 24px;      /* Size of the stars */
    color: #ddd;          /* Default color for empty stars */
    margin: 0 auto;       /* Center the div horizontally if it has a specific width */
    width: 100%;          /* Take full width of the container to center content */
    padding: 0px 0;      /* Optional: Adds some vertical space */
}

.star {
    cursor: pointer;      /* Changes cursor to pointer when hovering over stars, if interactive */
}

.star.full {
    color: gold;          /* Color for full stars */
}

.star.half {
    /* Use a linear gradient for half star */
    background: linear-gradient(90deg, gold 50%, #ddd 50%);
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent; /* Ensure text fill color is transparent for cross-browser */
}

@media (min-width: 768px) {
    .star-rating {
        justify-content: flex-start; /* Align items to the left on large screens */
    }
}
